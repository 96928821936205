/* margin xs */
.marginXsL {
  margin-left: var(--spacing-xs);
}
.marginXsR {
  margin-right: var(--spacing-xs);
}
.marginXsT {
  margin-top: var(--spacing-xs);
}
.marginXsB {
  margin-bottom: var(--spacing-xs);
}
/* padding xs */
.paddingXsL {
  padding-left: var(--spacing-xs);
}
.paddingXsR {
  padding-right: var(--spacing-xs);
}
.paddingXsT {
  padding-top: var(--spacing-xs);
}
.paddingXsB {
  padding-bottom: var(--spacing-xs);
}

/* margin s */
.marginSL {
  margin-left: var(--spacing-s);
}
.marginSR {
  margin-right: var(--spacing-s);
}
.marginST {
  margin-top: var(--spacing-s);
}
.marginSB {
  margin-bottom: var(--spacing-s);
}

/* padding s */
.paddingSL {
  padding-left: var(--spacing-s);
}
.paddingSR {
  padding-right: var(--spacing-s);
}
.paddingST {
  padding-top: var(--spacing-s);
}
.paddingSB {
  padding-bottom: var(--spacing-s);
}

/* margin m */
.marginML {
  margin-left: var(--spacing-m);
}
.marginMR {
  margin-right: var(--spacing-m);
}
.marginMT {
  margin-top: var(--spacing-m);
}
.marginMB {
  margin-bottom: var(--spacing-m);
}

/* padding m */
.paddingML {
  padding-left: var(--spacing-m);
}
.paddingMR {
  padding-right: var(--spacing-m);
}
.paddingMT {
  padding-top: var(--spacing-m);
}
.paddingMB {
  padding-bottom: var(--spacing-m);
}

/* margin l */
.marginLL {
  margin-left: var(--spacing-l);
}
.marginLR {
  margin-right: var(--spacing-l);
}
.marginLT {
  margin-top: var(--spacing-l);
}
.marginLB {
  margin-bottom: var(--spacing-l);
}

/* padding l */
.paddingLL {
  padding-left: var(--spacing-l);
}
.paddingLR {
  padding-right: var(--spacing-l);
}
.paddingLT {
  padding-top: var(--spacing-l);
}
.paddingLB {
  padding-bottom: var(--spacing-l);
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.alignCenter {
  align-items: center;
}
